var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.active
      ? _c("nav", [
          _c(
            "ul",
            _vm._l(_vm.sections, function(section) {
              return _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "v-smooth_scroll",
                    class: { "is-active": _vm.currentSection === section.hash },
                    attrs: { href: "#" + section.hash },
                    on: { click: _vm.execScroll }
                  },
                  [_c("span", [_vm._v(_vm._s(section.label))])]
                )
              ])
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }