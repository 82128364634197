<template lang="pug">
  transition(name="fade")
    nav(v-if="active")
      ul
        li(v-for="section in sections")
          a(v-bind:href="`#${section.hash}`", v-bind:class="{'is-active':currentSection === section.hash}", v-on:click="execScroll").v-smooth_scroll
            span {{ section.label }}
</template>

<style lang="scss" scoped>
nav {
  display: none;
  width: 1200px;
  height: 0;
  position: fixed;
  bottom: 50vh;
  right: 0;
  z-index: 9999;

  @include mq() {
    display: block;
  }

  ul {
    position: absolute;
    top: 0;
    right: 20px;
    transform: translateY(-50%);
    transition: opacity 0.2s ease 0s;

    li {
      text-align: right;
      &:not(:last-child) {
        margin-bottom: 30px;
        position: relative;
      }

      a {
        display: inline-block;
        font-size: 1.8rem;
        line-height: 1.2;
        color: #999;
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: right;

        &:hover,
        &.is-active {
          color: #af6ed7;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }

        &:after {
          content: '\00a0●';
          display: inline-block;
        }
      }
    }
  }

  @include mq() {
    display: flex;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

<script>
import Vue from 'vue';
import SmoothScroll from 'smooth-scroll';

export default {
  data: function() {
    return {
      active: false,
      scrollThreshold: 550,
      currentSection: null,
      sections: [
        {
          label: 'NEWS',
          hash: 'news'
        },
        {
          label: 'CONCEPT',
          hash: 'concept'
        },
        {
          label: 'MENU',
          hash: 'menu'
        },
        {
          label: '鍼灸治療・勝山浩尉智\n\r美容矯正',
          hash: 'acupuncture'
        },
        {
          label: '院長紹介',
          hash: 'director'
        },
        {
          label: 'BLOG',
          hash: 'blogsection'
        },
        {
          label: 'よくあるご質問',
          hash: 'faq'
        },
        {
          label: 'ACCESS',
          hash: 'access'
        }
      ]
    }
  },
  methods: {
    setScrollEvent: function() {
      let scrollTicking = false;
      if(scrollTicking) return false;
      document.addEventListener('scroll', e => {
        requestAnimationFrame(e => {
          this.setActive();
          this.toggleActive();
          this.toggleExists();
          scrollTicking = true;
        });
      }, {
        passive: true,
      });
    },
    toggleActive: function() {
      if(window.scrollY > this.scrollThreshold) {
        this.$data.active = true;
      } else {
        this.$data.active = false;
      }
    },
    getSectionsOffset: function() {
      this.$data.sections.forEach(section => {
        const target = document.getElementById(section.hash);
        section.top = target.getBoundingClientRect().top + this.getCurrentScrollTop();
        section.bottom = target.getBoundingClientRect().bottom + this.getCurrentScrollTop();
      });
    },
    toggleExists: function() {
      const currentTop = this.getCurrentScrollTop();
      if(currentTop > this.$data.sections[0].top) {
        this.$data.show = true;
      } else {
        this.$data.show = false;
      }
    },
    setActive: function() {
      const currentTop = this.getCurrentScrollTop();
      const currentSection = this.$data.sections.find(section => {
        return currentTop > section.top && section.bottom > currentTop;
      });

      if(currentSection) {
        this.$data.currentSection = currentSection.hash;
      } else {
        this.$data.currentSection = null;
      }
    },
    getCurrentScrollTop: function() {
      return window.pageYOffset;
    },
    execScroll: function(target) {
    },
  },
  mounted: function() {
    this.setScrollEvent();
    this.getSectionsOffset();

    new SmoothScroll('.v-smooth_scroll', {
      speed: 500,
      speedAsDuration: true,
      offset: 88,
    });
  }
}
</script>
