import 'swiper/dist/css/swiper.css';
import Swiper from 'swiper';
import Vue from 'vue';
import $ from 'jquery';

import SectionNavigation from '../vue/SectionNavigation.vue';

document.addEventListener('DOMContentLoaded', e => {
  // KeyVisual
  new Swiper('.js-kv .swiper-container', {
    effect: 'fade',
    speed: 1000,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    allowTouchMove: false,
    pagination: {
      el: '.js-kv .swiper-pagination',
      type: 'bullets',
      clickable: true,
    }
  });

  // Pick Up Movie
  new Swiper('.js-pick_up_movie .swiper-container', {
    loop: true,
    speed: 500,
    allowTouchMove: false,
    pagination: {
      el: '.js-pick_up_movie .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-pick_up_movie .swiper-navigation-button-next',
      prevEl: '.js-pick_up_movie .swiper-navigation-button-prev',
    },
    spaceBetween: 30,
    slidesPerView: 3,
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 60
      }
    }
  });

  // faq
  (() => {
    let finishFlg = true;

    $(document).on('click', '.js-faq_block__toggle', function(e) {
      e.preventDefault();

      if(!finishFlg) return false;
      finishFlg = false;

      $(this).parents('.js-faq_block').toggleClass('is-active');
      $(this).parents('.js-faq_block').find('.js-faq_block__txt').slideToggle(500, function() {
        finishFlg = true;
      });
    });
  })();

  // footer btn
  const toggleFooterContact = () => {
    if($(window).scrollTop() > $('.js-top-news_section').offset().top) {
      $('.js-footer_contact_btn').not('.is-show').addClass('is-show');
    } else {
      $('.js-footer_contact_btn.is-show').removeClass('is-show');
    }
  }

  // セクションナビゲーション
  Vue.component('section-navigation', SectionNavigation);
  new Vue({ el: '#v-section_navigation' });

  // set event listeners
  (() => {
    let scrollTicking = false;
    if(scrollTicking) return false;
    document.addEventListener('scroll', e => {
      requestAnimationFrame(e => {
        toggleFooterContact();
        scrollTicking = true;
      });
    }, {
      passive: true,
    });
  })();
});
